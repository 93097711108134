import { apiSlice } from '../api/api-slice';
import { PaginatedResult, MarketViewModel } from '../api/models';

export interface MarketListRequest {
    pageSize: number;
    filter?: string | null;
    current: number;
    total?: number;
    sortBy?: string | null;
}

export const marketsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchMarkets: builder.query<PaginatedResult<MarketViewModel>, MarketListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/markets?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
      })
    };
  },
});

export const { useFetchMarketsQuery, useLazyFetchMarketsQuery } = marketsApi;