import { apiSlice } from '../api/api-slice';
import { CityViewModel, PaginatedResult } from '../api/models';

export interface TablePageRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface AddCityRequest {
  city: string,
  zipCode: string,
  country: string,
  state?: string
}

export interface EditCityRequest {
  cityId: number;
  latitude: number;
  longitude: number;
  geoStateId?: number,
  timeZoneId?: number,
  geoMarketId?: number,
  geoZoneID?: number,
  isSearchable: boolean,
  geoCityTypeId: number
}

export const citiesApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchCities: builder.query<PaginatedResult<CityViewModel>, TablePageRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/cities?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        providesTags: (_data) => _data?.results ? [..._data.results.map(({ id }) => ({ type: 'City' as const, id }))] : []
      }),
      fetchSingleCity: builder.query<CityViewModel, number>({
        query(cityId) {
          return `/cities/${cityId}`;
        },
        providesTags: (_data, _error, cityId) => [{ type: 'City', id: `${cityId}` }]
      }),
      addCity: builder.mutation<CityViewModel, AddCityRequest>({
        query: (request) => ({
            url: 'cities',
            method: 'POST',
            body: request
        })
      }),
      updateCity: builder.mutation<CityViewModel, EditCityRequest>({
        query: (request) => ({
            url: `cities/${request.cityId}`,
            method: 'PUT',
            body: request
        }),
        invalidatesTags: (_data, _error, arg) => [{ type: 'City', id: `${arg.cityId}` }]
      })
    };
  },
});

export const { 
  useFetchCitiesQuery, 
  useFetchSingleCityQuery,
  useAddCityMutation, 
  useUpdateCityMutation 
} = citiesApi;
