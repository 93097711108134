import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../style/img/img_logo.png';
import { Col, Layout, Menu, Row } from 'antd';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { DefaultLinkName } from '../../core/defaults';
import { MenuInfo } from 'rc-menu/lib/interface';
import { appRoutes, getLink } from '../../App-router';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useAppSelector } from '../../hooks';

const { Header, Footer, Content } = Layout;

interface AppLayoutProps {
  children?: React.ReactNode | undefined;
}

export default function AppLayout(props: AppLayoutProps) {
  const { logout } = useAuth0();
  const routes = appRoutes[0].routes;
  const params: any = useParams<any>();
  const [ selectedMenuItem, setSelectedMenuItem ] = useState(DefaultLinkName);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const handleMenuClick = useCallback(
    (e: MenuInfo) => {
      const linkParams = {
        ...params,
      };
      setSelectedMenuItem(e.key);
      navigate(getLink(e.key, linkParams));
    },
    []
  );

  return (
    <Layout className="layout">
      <Header className="header">
        <Row>
          <Col className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo"/>
            </Link>
          </Col>
          <Col flex="auto">
            {
              <Menu mode="horizontal" onClick={handleMenuClick} defaultSelectedKeys={['1']} selectedKeys={[selectedMenuItem]}>
                {routes && routes
                  .filter((x) => x.showInNavigation)
                  .map((route, i) => (<Menu.Item key={route.name}>{route.title}</Menu.Item>))}
              </Menu>
            }
          </Col>
          <Col flex="auto"></Col>
          <Col className="user" flex="0 0 30px">
            <Menu mode="vertical">
              <SubMenu key="sub1" icon={<img style={{ width: 36, height: 36, borderRadius: '50%'}} src={user?.picture} />}>
                <Menu.Item key="1" onClick={() => logout({ returnTo: window.location.origin })}><LogoutOutlined /> Logout</Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Content
        className="site-layout-background"
        style={{
          minHeight: '88vh',
        }}
      >
        <Outlet />
      </Content>
      <Footer style={{ textAlign: 'center' }} />
    </Layout>
  );
}

