export interface PaginatedResult<TModel> {
  totalCount?: number;
  pageSize?: number;
  pageNumber?: number;
  results?: TModel[] | undefined;
}

export interface CityViewModel {
  id: number,
  state: string;
  postalCode: string;
  timezone: string;
  latitude: number;
  longitude: number;
  name: string,
  zipCode: string,
  countryId: number,
  country: string,
  geoStateId?: number,
  stateCode?: string,
  geoZoneID?: number,
  isSearchable: boolean,
  sourceId: number,
  geoCityTypeId: number,
  timeZoneId?: number,
  timeZoneOffset?: number,
  timeZoneStandardName?: string,
  geoMarketId?: number,
  geoMarket: string,
  populationDensity?: number,
  countryAlpha2?: string,
  countryAlpha3?: string
}

export interface Timezone {
  id: number;
  description: string;
  daylightName: string
  standardName: string
  utcOffset: number;
  supportsDaylightSavingTime: boolean
}

export interface StateViewModel {
  id: number;
  name: string;
  code: string;
  longitude: number;
  latitude: number;
  geoRegionId: number;
  geoCountryId: number;
  island: boolean;
}

export interface MarketViewModel {
  id: number;
  zipCode: string;
  name: string;
  geoStateId: number;
  geoCityId: number;
  disabled: boolean;
  disabledBy?: number;
  disabledByDt?: Date;
}

export enum GeoCityType
{
  'Default',
  'Branch',
  'Community Post Office (CPO)',
  'Non Postal Community Name',
  'Post Office',
  'Station',
  'Urbanization',
  'APO/FPO Military',
  'P.O. Box'
}