import { apiSlice } from '../api/api-slice';
import { PaginatedResult, StateViewModel } from '../api/models';

export interface StateListRequest {
    pageSize: number;
    filter?: string | null;
    current: number;
    total?: number;
    sortBy?: string | null;
}

export const statesApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchStates: builder.query<PaginatedResult<StateViewModel>, StateListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/states?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
      })
    };
  },
});

export const { useFetchStatesQuery, useLazyFetchStatesQuery } = statesApi;