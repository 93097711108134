import AppLayout from './components/common/AppLayout';
import { RouteDefinition } from './core/route';
import NotFound from './components/common/NotFound';
import { generatePath } from 'react-router-dom';
import { citiesRoutes}  from './features/cities/city-router';

export const appRoutes: RouteDefinition[] = [
  {
    name: 'Root',
    path: '/',
    component: AppLayout,
    props: { getRoutes: () => appRoutes },
    routes: [
      ...citiesRoutes,
      {
        name: 'NotFound',
        path: '',
        component: NotFound,
      },
    ],
  },
  {
    name: 'DefaultNotFound',
    path: '',
    component: AppLayout,
    props: { getRoutes: () => appRoutes },
    routes: [
      {
        name: 'NotFound',
        path: '',
        component: NotFound,
      },
    ],
  },
];

const findLink = (name: string, routes: RouteDefinition[] | undefined): string[] => {
  if (!routes) return [];
  const routeParts: string[] = [];
  for (const route of routes) {
    const innerMatch = findLink(name, route.routes);
    if (route.name === name) {
      routeParts.push(route.path);
      return routeParts;
    } else if (innerMatch.length > 0) {
      routeParts.push(route.path);
      routeParts.push(...innerMatch);
    }
  }
  return routeParts;
};

export const getLink = (name: string, params: any, query: any | undefined = undefined) => {
  let linkTemplate = findLink(name, appRoutes);
  if (linkTemplate.length === 0) throw new Error(`${name} route was not found in the router`);

  linkTemplate = linkTemplate.map(x => {
    if (x === '/') {
      return '';
    }
    return x;
  })

  const link = `${generatePath(linkTemplate.join('/'), params)}${
    query ? '?' + new URLSearchParams(query).toString() : ''
  }`;
  return link;
};

export const getPath = (match: any) => {
  return null;
};
