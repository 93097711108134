import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    PageHeader,
    Spin,
    Form,
    Input,
    Button,
    Tooltip,
    Space,
    Breadcrumb,
    Alert,
    message,
    Row,
    Col,
    Switch,
    Select,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { EditCityRequest, useFetchSingleCityQuery, useUpdateCityMutation } from '../city-api';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLink } from '../../../App-router';
import { useFetchTimezonesQuery } from '../../settings/settings-api';
import { CityViewModel, MarketViewModel, PaginatedResult, StateViewModel, GeoCityType } from '../../api/models';
import { useFetchStatesQuery } from '../../states/state-api';
import { useFetchMarketsQuery } from '../../markets/market-api';

const { Option } = Select;

export default function EditCityScreen(props: any) {

    const { cityId } = useParams();

    const { data: timezones = [], isFetching: isFetchingTimeZones } = useFetchTimezonesQuery();
    const [marketSearchText, setMarketSearchText] = useState<string>('');

    const { data: cityData, isFetching: isFetchingCity } = useFetchSingleCityQuery(Number(cityId));

    const { data: markets = {} }: { data?: PaginatedResult<MarketViewModel> | undefined; isFetching?: boolean | undefined; error?: any; } 
    = useFetchMarketsQuery({
        current: 1,
        pageSize: 1000,
        filter: `gm.Name:*${marketSearchText}*`,
        sortBy: `gm.Name`,
    }, { skip: !marketSearchText });

    const { data: states = {}, isFetching: isFetchingStates }: {
        data?: PaginatedResult<StateViewModel> | undefined;
        isFetching?: boolean | undefined;
        error?: any;
    } = useFetchStatesQuery({
        current: 1,
        pageSize: 1000,
        filter: `gs.GeoCountryID:"${cityData?.countryId}"`,
        sortBy: `gs.Name`,
    }, { skip: !cityData });

    const [updateCity, { isLoading, error }] = useUpdateCityMutation();
    const navigate = useNavigate();

    const getErrorDetail = (error: any) => 'data' in error
        ? 'detail' in error.data
            ? error.data.detail
            : error.data
        : error;

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        setMarketSearchText(cityData?.geoMarket!);
    }, [cityData, form]);

    const onMarketSearch = (searchText: string) => {
        setMarketSearchText(searchText);
    };
   
    const geoCityTypeOptions = Object.keys(GeoCityType)
    .filter((value) => isNaN(Number(value)))
    .map((value) => ({
        label: value === 'Default'? ' ' : value,
        value : GeoCityType[value as keyof typeof GeoCityType]
    }))
     
    const onSubmit = async (formData: CityViewModel) => {
        try {
            const requestData: EditCityRequest = {
                cityId: formData.id,
                latitude: formData.latitude,
                longitude: formData.longitude,
                geoStateId: formData.geoStateId,
                geoMarketId: formData.geoMarketId,
                timeZoneId: formData.timeZoneId,
                geoZoneID: formData.geoZoneID,
                isSearchable: formData.isSearchable,
                geoCityTypeId: formData.geoCityTypeId
            }

            await updateCity(requestData).unwrap();

            message.success(`City ${formData.name} was updated with success!`);
        } catch (e) {
            console.error(e);
        }
    };

    const onBackHandle = useCallback(() => {
        navigate(getLink('CityList', { ...props }));
    }, []);

    return (
        <PageHeader
            breadcrumb={
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/"><HomeOutlined /></Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>Edit City</BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <Card
                title={
                    <>
                        <Space>
                            <Tooltip title="Go back">
                                <Button type="link" icon={<ArrowLeftOutlined />} onClick={onBackHandle}></Button>
                            </Tooltip>
                            <Title
                                level={4}
                                style={{
                                    marginBottom: 0,
                                    display: 'inline-block',
                                }}
                            >
                                Edit City
                            </Title>
                        </Space>
                    </>
                }
            >

                <Spin spinning={isLoading}>
                    <Form
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 8 }}
                        autoComplete="off"
                        onFinish={onSubmit}
                        initialValues={cityData}>
                        <Form.Item name="id" rules={[{ required: true }]}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item label="City Name" name="name" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Postal Code" name="postalCode">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Lat">
                            <Row gutter={32}>
                                <Col span={12}>
                                    <Form.Item name="latitude">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Lng" name="longitude">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="State/Province" name="geoStateId">
                            <Select
                                className="form-select"
                                showSearch={true}
                                disabled={isFetchingCity || isFetchingStates}
                            >
                                {states.results?.map((state, index) => (
                                    <Option key={index} value={state.id || ''}>
                                        {state.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Market" name="geoMarketId">
                            <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={onMarketSearch}
                                notFoundContent={null}
                            >
                                {markets.results?.map((market, index) => (
                                    <Option key={index} value={market.id || ''}>
                                        {market.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="City Type" name="geoCityTypeId">
                        <Select
                        options={geoCityTypeOptions}
                        />
                        </Form.Item>
                        <Form.Item label="Timezone" name="timeZoneId" rules={[{ required: true }]}>
                            <Select
                                className="form-select"
                                disabled={isFetchingCity || isFetchingTimeZones}
                            >
                                {timezones.map((timezone, index) => (
                                    <Option key={index} value={timezone.id || ''}>
                                        {timezone.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Searchable" name="isSearchable" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                            <Button type="primary" htmlType="submit">
                                Update City
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
                {error && <Alert message="Error" description={getErrorDetail(error)} type="error" showIcon />}
            </Card>
        </PageHeader>
    );
}