import React, { useCallback } from 'react';
import {
  Card,
  PageHeader,
  Spin,
  Form,
  Input,
  Button,
  Tooltip,
  Space,
  Breadcrumb,
  Alert,
  message,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { AddCityRequest, useAddCityMutation } from '../city-api';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { Link, useNavigate } from 'react-router-dom';
import { getLink } from '../../../App-router';


export default function AddCityScreen(props: any) {
  const [addCity, { isLoading, error  }] = useAddCityMutation();
  const navigate = useNavigate();

  const getErrorDetail = (error: any) => 'data' in error 
                                        ? 'detail' in error.data 
                                          ? error.data.detail
                                          : error.data
                                        : error;

  const onSubmit = async (formData: AddCityRequest) => {
    try {
      await addCity(formData).unwrap();

      message.success(`City ${formData.city} was created with success!`);
    } catch (e) {
      console.error(e);
    }
  };

  const onBackHandle = useCallback(() => {
    navigate(getLink('CityList', { ...props }));
  }, []);

  return (
    <PageHeader
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/"><HomeOutlined /></Link>
          </BreadcrumbItem>
          <BreadcrumbItem>Add City</BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Card
        title={
          <>
            <Space>
              <Tooltip title="Go back">
                <Button type="link" icon={<ArrowLeftOutlined />} onClick={onBackHandle}></Button>
              </Tooltip>
              <Title
                level={4}
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                }}
              >
                Add City
              </Title>
            </Space>
          </>
        }
      >
        
        <Spin spinning={isLoading}>
          <Form 
            labelCol={{ span: 4 }} 
            wrapperCol={{ span: 8 }} 
            autoComplete="off"
            onFinish={onSubmit}>
            <Form.Item label="City Name" name="city" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Postal Code" name="zipCode" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="State/Province" name="state">
              <Input />
            </Form.Item>
            <Form.Item label="Country" name="country" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
              <Button type="primary" htmlType="submit">
                Add City
              </Button>
            </Form.Item>
          </Form>
        </Spin>
        {error && <Alert message="Error" description={getErrorDetail(error)} type="error" showIcon />}
      </Card>
    </PageHeader>
  );
}
