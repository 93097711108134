import React, { useEffect } from 'react';
import './style/main.less';

import { useAppSelector, useAppDispatch } from './hooks';
import { login } from './auth/auth-slice';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { appRoutes, getLink } from './App-router';

if (process.env.REACT_APP_ENABLE_MOCK === 'true' && process.env.NODE_ENV !== 'test') {
  const { mockWorker } = require('./mocks/mockWorker');
  mockWorker.start();
}

function App() {
  const { loginWithRedirect, isAuthenticated, user, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const hasAccess = useAppSelector((state) => state.auth.hasAccess);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {

      (window as any).pendo.initialize({
        visitor: {
          id: user.email,
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
        },
      });

      getAccessTokenSilently().then((token) => {
        dispatch(
          login({
            hasAccess: isAuthenticated,
            token: token,
            user: user,
          })
        );
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !user) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, user]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user && !hasAccess) {
      logout();
    }
  }, [hasAccess]);

  const RouteWithSubRoutes = (route: any, key: number) => {
    const {path, routes, component: Component, name, isDefaultRoute, ...otherProps } = route;
    return (
      <React.Fragment key={`${name}-${key}`} >
      <Route 
        path={path} 
        element={<Component {...otherProps} />} >
        { routes && routes.map((innerRoute: any, i: number) => RouteWithSubRoutes(innerRoute, i)) }
      </Route>
      {
        isDefaultRoute &&
        <Route 
          path="" 
          element={<Navigate replace to={getLink(name, '')} />} />
      }
      </React.Fragment>
    );
  };

  if (isLoading) {
    return <>Loading</>;
  } else if (isAuthenticated && hasAccess) {
    return (
    <BrowserRouter>
      <Routes>
          {appRoutes.map((route, i) => RouteWithSubRoutes(route, i))}
      </Routes>
    </BrowserRouter>);
  } else {
    return <></>;
  }
}

export default App;
