import { RouteDefinition } from '../../core/route';
import AddCityScreen from './screens/add-city';
import CitiesScreen from './screens/cities-screen';
import EditCityScreen from './screens/edit-city';

export const citiesRoutes: RouteDefinition[] = [
  {
    name: 'CityList',
    title: 'Cities',
    path: 'cities',
    component: CitiesScreen,
    showInNavigation: true,
    isDefaultRoute: true,
  },
  {
    name: 'Add-City',
    title: 'Add City',
    path: 'city/add',
    component: AddCityScreen,
    showInNavigation: false
  },
  {
    name: 'Edit-City',
    title: 'Edit City',
    path: 'cities/:cityId',
    component: EditCityScreen,
    showInNavigation: false
  }
];
