import React, { useCallback, useEffect, useState } from 'react';
import { TablePageRequest, useFetchCitiesQuery } from '../city-api'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Breadcrumb, Card, PageHeader, Table, Input, Form, Button, Space } from 'antd';
import { HomeOutlined, GlobalOutlined, CheckOutlined } from '@ant-design/icons';

import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import { CityViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const { Search } = Input;

export default function CitiesScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const [form] = Form.useForm();
  const navigate =  useNavigate();

  const getCurrentState = (): TablePageRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy'),
  });

  const [pagination, setPagination] = useState<TablePageRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<CityViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchCitiesQuery(pagination);

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const tableAlerts: TableState = {
    columns: [
      {
        title: 'Name',
        dataIndex: '',
        key: 'name',
        sorter: true,
        render: (city: CityViewModel) => (
          <>
            <Link to={getLink('Edit-City', { cityId: city.id })}>
              {city.name}
            </Link>
          </>
        )
      },
      {
        title: 'State',
        dataIndex: 'stateName',
        key: 'state',
      },
      {
        title: 'Postal Code',
        dataIndex: 'postalCode',
        key: 'zipcode',
        sorter: true
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: true
      },
      {
        title: 'Location',
        dataIndex: '',
        key: 'location',
        render: (city: CityViewModel) => {
          return <>{city.latitude}, {city.longitude}</>;
        }
      },
      {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timeZoneStandardName',
        sorter: true
      },
      {
        title: 'Searchable?',
        dataIndex: 'isSearchable',
        key: 'isSearchable',
        sorter: true,
        render: (isSearchable: boolean) => {
          if (isSearchable) return <CheckOutlined />;
          return <Space />;
        }
      }
    ],
  }

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CityViewModel> | SorterResult<CityViewModel>[],
    extra: TableCurrentDataSource<CityViewModel>
  ) => {
    
    const { columnKey, order } = (sorter as SorterResult<CityViewModel>);
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'CityList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const onSearch = (value: string) => {
    navigate(
      getLink(
        'CityList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: constructFilterQuery(value),
        }
      )
    );
  }

  const constructFilterQuery = (searchText: string | null | undefined) => {
    return searchText ? `c.Name: "*${searchText}*" OR gs.Name: "*${searchText}*" OR gs.StateCode: "*${searchText}*" OR c.ZipCode: "*${searchText}*"` : '';
  }

  const onCreateNew = useCallback(() => {
    navigate(getLink('Add-City', { ...props, ...params }));
  }, [params]);

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="home">
              <Link to="/"><HomeOutlined /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="cities">
              <GlobalOutlined />
              <span>Cities</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 8, padding: 8}}>
            <Search placeholder="Search City, State, or Postal Code" onSearch={onSearch} style={{ width: 463 }} />
            <Button type="default" data-testid="create-new-monitor" style={{}} onClick={onCreateNew}>
              Add City
            </Button>
          </div>
          <Table
            dataSource={pagedData.results}
            onChange={handleTableChange}
            rowKey="id"
            pagination={{ ...pagination, total: pagedData.totalCount, showTotal: (total: number) => `Total ${total} items` }}
            columns={tableAlerts.columns}
            loading={isFetching}
          />
        </Card>
      </PageHeader>
    </div>
  );
}
