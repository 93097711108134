import { apiSlice } from '../api/api-slice';
import { Timezone } from '../api/models';

export const settingsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchTimezones: builder.query<Timezone[], number | void>({
        query() {
          return `/timezones`;
        },
      })
    };
  },
});

export const { useFetchTimezonesQuery } = settingsApi;